import React, { useState, useEffect, useContext, useRef, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import { i18n } from "../../translate/i18n"
import ButtonWithSpinner from "../ButtonWithSpinner"
import { AuthContext } from "../../context/Auth/AuthContext"

import toastError from "../../errors/toastError"

import api from "../../services/api"

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  paper: {
    minWidth: "300px"
  },
  linearProgress: {
    marginTop: "5px"
  }
}))

const ContactCreateTicketModal = ({ modalOpen, onClose, contactId }) => {
  const { user } = useContext(AuthContext)

  let isMounted = useRef(true)

  const history = useHistory()
  const [queues, setQueues] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedQueue, setSelectedQueue] = useState('')
  const [itemHover, setItemHover] = useState(-1)

  const [selectedWhatsId, setSelectedWhatsId] = useState()
  const [whatsQueue, setWhatsQueue] = useState()
  const [disabled, setDisabled] = useState(false)

  const classes = useStyles()

  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {

      const fetchMatchQueueUserOfficialWhatsapp = async () => {
        try {

          const { data } = await api.get("/whatsapp/official/matchQueueUser", { params: { userId: user.id, queueId: selectedQueue }, })

          setQueues(data)

        } catch (err) {
          console.log(err)
        }
      }

      fetchMatchQueueUserOfficialWhatsapp()

    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }, [user, selectedQueue])

  const handleClose = () => {
    onClose()
  }

  const handleSaveTicket = useCallback(async (contactId, userId, queueId, selectedWhatsId, whatsQueue, queues) => {

    if (queues && queues.length === 1 && queues[0].disable) {
      toast.warn('Não é possível criar um Ticket porque a fila a qual você esta adicionado(a) não está associado a nenhum numero!')
      return
    }

    if (!contactId || !userId) {
      console.log("Missing contactId or userId")
      return
    };
    if (!queueId) {
      toast.warning("Nenhuma Fila Selecionada")
      return
    }
    if (isMounted.current) setLoading(true)

    if (whatsQueue && !selectedWhatsId) {
      toast.warn('Selecione um numero!')
      return
    }

    const delayDebounceFn = setTimeout(() => {
      const ticketCreate = async () => {
        try {
          const { data: ticket } = await api.post("/tickets", {
            contactId: contactId,
            userId: userId,
            queueId: queueId,
            status: "open",
            whatsappId: selectedWhatsId
          })
          history.push(`/tickets/${ticket.id}`)

          setWhatsQueue(null)
          setSelectedWhatsId(null)
        } catch (err) {
          toastError(err)
        }
        if (isMounted.current) setLoading(false)

      }
      ticketCreate()
    }, 300)
    return () => clearTimeout(delayDebounceFn)

  }, [history])

  useEffect(() => {

    if (selectedQueue && (selectedQueue.length === 0 || !selectedQueue)) {
      setDisabled(true)
      setWhatsQueue(null)
    }

    if (!Array.isArray(whatsQueue)) {
      setSelectedWhatsId(null)
      setWhatsQueue(null)
    }

    return () => {
      isMounted.current = false
    }
  }, [modalOpen, contactId, user.id, selectedQueue, handleSaveTicket, queues.length, selectedWhatsId, whatsQueue, queues])


  useEffect(() => {

    if (!selectedQueue) return

    setDisabled(true)

    const delayDebounceFn = setTimeout(() => {

      const fetChMatchQueueOfficialWhatsapp = async () => {
        try {

          const { data } = await api.get("/whatsapp/official/matchQueue", { params: { userId: user.id, queueId: selectedQueue }, })

          console.log('WHATSAPP DATA: ', data)

          setWhatsQueue(data)

          setDisabled(false)

        } catch (err) {
          console.log(err)
        }
      }

      fetChMatchQueueOfficialWhatsapp()

    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }, [selectedQueue, user.id])

  useEffect(() => {
    console.log('selectedWhatsId: ', selectedWhatsId)
    console.log('whatsQuee: ', whatsQueue)
  }, [whatsQueue, selectedWhatsId])

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="xs" scroll="paper" classes={{ paper: classes.paper }}>
      <DialogTitle id="form-dialog-title">
        {i18n.t("newTicketModal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <FormControl variant="outlined" className={classes.maxWidth}>
          <InputLabel>{i18n.t("Selecionar Fila")}</InputLabel>
          <Select
            value={selectedQueue}
            onChange={(e) => setSelectedQueue(e.target.value)}
            label={i18n.t("Filas")}
          >
            <MenuItem value={''}>&nbsp;</MenuItem>
            {queues.map(({ id, color, name, disable }) => (
              <MenuItem
                key={id}
                disabled={disable}
                value={id}
                onMouseEnter={() => setItemHover(id)}
                onMouseLeave={() => setItemHover(-1)}
                style={{
                  background: id !== itemHover ? "white" : color,
                }}
              >{name[0].toUpperCase() + name.slice(1).toLowerCase()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      {whatsQueue && Array.isArray(whatsQueue) ?
        <DialogContent dividers>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>{i18n.t("Selecionar Numero")}</InputLabel>
            <Select
              value={selectedWhatsId}
              onChange={(e) => setSelectedWhatsId(e.target.value)}
              label={i18n.t("Numeros")}
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {whatsQueue.map(({ id, number, phoneNumberId }) => (
                <MenuItem
                  key={id}
                  value={id}
                >{phoneNumberId ? `${number} OFICIAL` : `${number}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        : <></>
      }



      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loading}
          variant="outlined"
        >
          {i18n.t("newTicketModal.buttons.cancel")}
        </Button>
        <ButtonWithSpinner
          onClick={() => handleSaveTicket(contactId, user.id, selectedQueue, selectedWhatsId, whatsQueue, queues)}
          variant="contained"
          color="primary"
          loading={loading}
          disabled={disabled}
        >
          {i18n.t("newTicketModal.buttons.ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  )
}

export default ContactCreateTicketModal